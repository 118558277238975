<template>
	<div class="administrativo_celulares">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Histórico de Vendas</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-menu ref="calendarDataVenda" v-model="menuOpenDataVenda" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="data_vendaToBR" label="Data Venda" outlined dense readonly v-bind="attrs"v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="filters.data_venda" no-title scrollable locale="pt-br">
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menuOpenDataVenda = false">Cancelar</v-btn>
								<v-btn text color="primary" @click="$refs.calendarDataVenda.save(filters.data_venda)">OK</v-btn>
							</v-date-picker>
						</v-menu>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Cliente" v-model="filters.cliente" outlined dense clearable :items="data.listagemClientes" item-text="cliente" item-value="cliente"></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Unidade Operacional" v-model="filters.unidade_operacional" outlined dense clearable :items="data.listagemUnidadesOperacionais" item-text="unidade_operacional" item-value="unidade_operacional"></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Tipo" v-model="filters.tipo" outlined dense clearable :items="data.listagemTipos" item-text="tipo" item-value="tipo"></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Veículo" v-model="filters.veiculo" outlined dense clearable :items="data.listagemVeiculos" item-text="veiculo" item-value="veiculo"></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3  class="pr-3">
						<v-text-field label="Placa" v-model="filters.placa" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Pagamento" v-model="filters.pagamento" outlined dense clearable :items="data.listagemTiposPagamento" item-text="pagamento" item-value="pagamento"></v-autocomplete>
					</v-flex>
					<v-flex xs6 sm4 md4 lg3 class="pr-3">
						<v-autocomplete label="Vendedor" v-model="filters.vendedor" outlined dense clearable :items="data.listagemVendedores" item-text="vendedor" item-value="vendedor"></v-autocomplete>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.valor="{ item }">
						R$ {{v_number_format(item.valor, 2, ',', '.')}}
					</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario'))
			},
			data: {
				listagemGeral: [{data_venda: 'Buscando...'}],
				listagemClientes: [{cliente: 'Buscando...'}],
				listagemUnidadesOperacionais: [{unidade_operacional: 'Buscando...'}],
				listagemTipos: [{tipo: 'Buscando...'}],
				listagemVeiculos: [{veiculo: 'Buscando...'}],
				listagemTiposPagamento: [{pagamento: 'Buscando...'}],
				listagemVendedores: [{vendedor: 'Buscando...'}]
			},
			filters: {
				cliente: '',
				unidade_operacional: '',
				tipo: '',
				veiculo: '',
				placa: '',
				data_venda: '',
				pagamento: '',
				vendedor: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,

			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menuOpenDataVenda: false,
		}
	},
	methods: {
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'venda_historico/exportar_historico_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'historico_vendas', []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral() {
			let dados = {};

			dados.headers = Object.keys(this.data.listagemGeral[0]).map((item) => {
				return {name: item};
			});

			dados.values = this.data.listagemGeral;
			
			console.log(dados);

			this.exportXlsAjax(dados, 'historico_vendas', []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'venda_historico/listagem_vendas',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'venda_historico/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemClientes = r.body.listagemClientes;
	                	thisEl.data.listagemUnidadesOperacionais = r.body.listagemUnidadesOperacionais;
	                	thisEl.data.listagemTipos = r.body.listagemTipos;
	                	thisEl.data.listagemVeiculos = r.body.listagemVeiculos;
	                	thisEl.data.listagemTiposPagamento = r.body.listagemTiposPagamento;
	                	thisEl.data.listagemVendedores = r.body.listagemVendedores;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		v_number_format (number, decimals, dec_point, thousands_sep) {
			return number_format(number, decimals, dec_point, thousands_sep);
		},
		formatDate (date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
	},
	computed: {
		data_vendaToBR(){
			return this.formatDate(this.filters.data_venda);
		},
		listagemHeadersHistorico() {
			return [
				{
					text: 'Colaborador Responsável',
					value: 'nome',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Vínculo',
					value: 'dt_vinculo',
					class: 'blue darken-4 white--text'
				}
			];
		},
		listagemHeaders() {
			return [
				{
					text: 'Data Venda',
					value: 'data_venda',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.data_vendaToBR) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.data_vendaToBR.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Unidade Operacional',
					value: 'unidade_operacional',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.unidade_operacional) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.unidade_operacional.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Cliente',
					value: 'cliente',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cliente) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cliente.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Tipo',
					value: 'tipo',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.tipo) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.tipo.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Veículo',
					value: 'veiculo',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.veiculo) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.veiculo.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Placa',
					value: 'placa',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.placa) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.placa.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Valor',
					value: 'valor',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Pagamento',
					value: 'pagamento',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.pagamento) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.pagamento.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Vendedor',
					value: 'vendedor',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.vendedor) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.vendedor.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				}
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
